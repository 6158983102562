<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-popup-submenu></q-popup-submenu>
        <!-- Charts-->
        <v-row class="mx-5 q-sticky-buffer" v-if="loading">
            <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-col>
        </v-row>
        <v-form class="mx-5 q-sticky-buffer" ref="form" v-model="form_valid" v-else>
            <v-row justify="space-between" align="stretch" class="mx-5">
                <v-col cols="12">
                    <h3>Edit Popup Notice</h3>
                </v-col>
                <v-col cols="12" sm="12" lg="6" class="cms">
                    <v-text-field :rules="[form_rules.required]" required v-model="popup_notice.title" label="Title"></v-text-field>
                    <minimal-text-editor v-if="hasRole('AgencyOwner')" ref="editor" class="elevation-4" v-model="popup_notice.content" label="Content"></minimal-text-editor>
                    <text-editor v-else ref="editor" class="elevation-4" v-model="popup_notice.content" label="Content"></text-editor>
                    <span style="float:right;" class="overline mt-2">Character Count: <span :class="characterCount() > 160 ? 'red--text font-weight-black' : ''">{{characterCount()}}</span></span>
                    Merge Fields!
                    <q-tooltip-details>
                        <div v-pre>{{AgentCode}}<br>{{AgentName}}<br>{{AgentPhone}}<br>{{UplineAgentName}}<br>{{BaseShopOwnerName}}<br>{{SFGDirectName}}</div>
                    </q-tooltip-details>
                    <v-row>
                        <v-col cols="7">
                            <v-switch v-model="needs_link" label="Needs Link"></v-switch>
                        </v-col>
                        <v-col cols="5" v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
                            <v-switch v-model="send_sms">
                                <template v-slot:label>
                                    Send SMS
                                    <v-btn small fab icon @click.stop="show_preview_sms_form = true">
                                        <v-icon>fas fa-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="needs_link">
                            <v-text-field v-model="popup_notice.link_text" label="Button Text"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="needs_link">
                            <v-text-field v-model="popup_notice.link" label="Button Link"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="needs_link">
                            <v-switch v-model="popup_notice.add_acknowledge_btn" label="Add seperate Acknowledge button"></v-switch>
                        </v-col>
                    </v-row>
                    <v-btn :large="$vuetify.breakpoint.smAndUp" :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'" color="secondary" @click="previewPopup" outlined class="mr-2 mb-2">Preview Notice</v-btn>
                    <v-btn :large="$vuetify.breakpoint.smAndUp" :width="$vuetify.breakpoint.smAndDown ? '100%' : 'auto'" color="primary" @click="savePopup" class="mb-2">Save Notice</v-btn>
                    <v-alert type="info" class="mt-4 text-left">Note: If you are editing an existing notice, anyone who has already acknowledged the previous notice <em>will also see the newly edited one</em>.</v-alert>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                    <v-switch v-model="popup_notice.active" :label="popup_notice.active == 1 ? 'Active' : 'Not Active'" value="1" :true-value="parseInt('1')" :false-value="parseInt('0')"></v-switch>
                    <v-menu v-model="startDateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="popup_notice.startDate" label="Start Date" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="popup_notice.startDate" @input="startDateSelector = false"></v-date-picker>
                    </v-menu>
                    <v-menu v-model="endDateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="popup_notice.endDate" label="End Date" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="popup_notice.endDate" @input="endDateSelector = false"></v-date-picker>
                    </v-menu>
                    <v-select :rules="[form_rules.required]" label="Target Audience" :items="available_audiences" required v-model="popup_notice.audience"></v-select>
                    <v-row v-if="hasRole('AgencyOwner')">
                        <v-col cols="12" v-if="popup_notice.audience == 'agents'">
                            <div>
                                <v-chip small close @click:close="removeAgent(key)" v-for="(agent, key) in popup_notice.selected_agents" :key="key" class="ma-1">{{agent.AgentName}}</v-chip>
                            </div>
                            <v-text-field v-model="agent_search" label="Search for Agent"></v-text-field>
                            <v-progress-linear v-if="agents_loading" indeterminate color="primary"></v-progress-linear>
                            <v-list class="result-list elevation-1">
                                <v-list-item v-for="(agent, key) in available_agents" :key="key" @click="addAgent(key)">
                                    {{ agent.AgentName }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" v-if="popup_notice.audience == 'agent_codes'">
                            <v-textarea v-model="pasted_agents" label="Agent Codes" messages="Enter comma delimated AgentCodes or OptIDs"></v-textarea>
                        </v-col>
                        <v-col cols="12" v-else-if="!['company', 'Corporate', 'Field', 'Wholesale', 'trigger_fast_track_completed', 'trigger_qms_commission'].includes(popup_notice.audience) && popup_notice.audience != null">
                            <div>
                                <v-chip small close @click:close="removeAgent(key)" v-for="(agent, key) in popup_notice.selected_agents" :key="key" class="ma-1">{{agent.AgentName}}</v-chip>
                            </div>
                            <v-text-field v-model="agent_search" label="Search for Agent"></v-text-field>
                            <v-progress-linear v-if="agents_loading" indeterminate color="primary"></v-progress-linear>
                            <v-list class="result-list elevation-1">
                                <v-list-item v-for="(agent, key) in available_agents" :key="key" @click="addAgent(key)">
                                    {{ agent.AgentName }}
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                    <v-row v-if="popup_notice.filters">
                        <v-col cols="12">
                            Filters:
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="contractStartDateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="popup_notice.filters.contractStartDate" label="Contract Start: From Date" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                                </template>
                                <v-date-picker v-model="popup_notice.filters.contractStartDate" @input="contractStartDateSelector = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="contractEndDateSelector" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="popup_notice.filters.contractEndDate" label="Contract Start: To Date" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                                </template>
                                <v-date-picker v-model="popup_notice.filters.contractEndDate" @input="contractEndDateSelector = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="popup_notice.filters.contractLevel" :items="[70,75,80,85,90,95,100,110,120]" :menu-props="{ maxHeight: '400' }" label="Contract Levels" multiple></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select v-model="popup_notice.filters.leadershipLevel" :items="['Sales Representative', 'Team Leader', 'Key Leader', 'Agency Owner', 'Agency Director', 'Regional Agency Director', 'Managing Vice President', 'Senior Vice President', 'Executive Vice President', 'Associate Partner', 'Senior Partner', 'Managing Parter', 'SFG']" :menu-props="{ maxHeight: '400' }" label="Leadership Levels" multiple></v-select>
                        </v-col>
                        <!-- Filter by isAdvisoryBoardMember -->
                        <v-col cols="6">
                            <v-checkbox v-model="popup_notice.filters.isAdvisoryBoardMember" label="Advisory Board Member">
                            </v-checkbox>
                        </v-col>
                        <!-- Filter by PCSAgentInd -->
                        <v-col cols="6">
                            <v-checkbox v-model="popup_notice.filters.PCSAgentInd" label="PCS Agent">
                            </v-checkbox>
                        </v-col>
                        <!-- Filter by SFG Direct -->
                        <v-col cols="6">
                            <v-checkbox v-model="popup_notice.filters.IsSFGDirect" label="SFG Direct (120)">
                            </v-checkbox>
                        </v-col>
                        <!-- Filter by State -->
                        <v-col cols="6">
                            <v-select label="State" :items="states" v-model="popup_notice.filters.State"></v-select>
                        </v-col>
                        <v-col cols="12">
                            Fast Track
                            <v-radio-group v-model="popup_notice.filters.fastTrack" row>
                                <v-radio label="Complete" value="1"></v-radio>
                                <v-radio label="Not Complete" value="-1"></v-radio>
                                <v-radio label="N/A" value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="show_preview_sms_form" width="500" persistent>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Preview SMS
                </v-card-title>
                <v-card-text>
                    <p>You can see a sample sms message by entering your mobile phone number below.</p>
                    <v-text-field v-model="preview_phone_number" label="Mobile phone number"></v-text-field>
                    <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.stop="show_preview_sms_form = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" @click.stop="previewSMS()">
                        Send
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <q-admin-notices :preview="popup_notice" :key="previewKey"></q-admin-notices>
    </v-container>
</template>
<script>
import QPopupSubmenu from '@/components/navigation/Submenus/QPopupSubmenu.vue'
import PopupNotice from '@/store/Models/PopupNotice'
import TextEditor from '@/CMS/editor/components/TextEditor.vue'
import MinimalTextEditor from '@/CMS/editor/components/MinimalTextEditor.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import { debounce } from 'vue-debounce'
import QTooltipDetails from '@/components/utils/QTooltipDetails.vue';
import QAdminNotices from '@/components/utils/QAdminNotices.vue'
import StateOptions from '@/store/StateOptions'
export default {
    name: "PopupNoticeEdit",
    data() {
        return {
            needs_link: false,
            send_sms: false,
            selectedAgentCode: null,
            popup_notice: {
                content: null,
                title: null,
                selected_agents: null,
                preview: false,
                active: 0,
                filters: {
                    State: null,
                    contractStartDate: null,
                    contractEndDate: null,
                    IsSFGDirect: null,
                    PCSAgentInd: null
                },
                add_acknowledge_btn: false,
            },
            agent_search: null,
            show_search_results: false,
            form_valid: false,
            available_agents: [],
            pasted_agents: '',
            startDateSelector: false,
            endDateSelector: false,
            previewKey: 1,
            contractStartDateSelector: false,
            contractEndDateSelector: false,
            show_preview_sms_form: false,
            preview_phone_number: localStorage.getItem("preview_phone_number"),
            states: StateOptions, // Populating the state drop downdown list
        };
    },
    mounted: function() {
        this.loadNotice(this.$route.params.id)
        this.states.unshift('');
    },
    computed: {
        loading() {
            return this.$store.state.entities.popup_notice.loading;
        },
        available_audiences: function() {
            if (this.hasRole('AgencyOwner')) {
                return [{
                    text: 'Total Agency',
                    value: 'mastershop'
                }, {
                    text: 'Direct Downline',
                    value: 'directdownline'
                }, {
                    text: 'Base Shop',
                    value: 'baseshop'
                }, {
                    text: 'Selected Agents',
                    value: 'agents'
                }]
            } else {
                return [{
                    text: 'Company Wide',
                    value: 'company'
                }, {
                    text: 'Selected Agent Downline',
                    value: 'directdownline'
                }, {
                    text: 'Selected Agent Base Shop',
                    value: 'baseshop'
                }, {
                    text: 'Selected Agents',
                    value: 'agents'
                }, {
                    text: 'Paste Agent Codes',
                    value: 'agent_codes'
                }, {
                    divider: true,
                    header: 'Triggers'
                }, {
                    text: 'Fast Track Completed',
                    value: 'trigger_fast_track_completed'
                }, {
                    text: 'PCS Commission',
                    value: 'trigger_qms_commission'
                }, {
                    text: 'New Agent',
                    value: 'trigger_new_agents'
                }, {
                    divider: true,
                    header: 'Divisions'
                }, {
                    text: 'Corporate',
                    value: 'Corporate'
                }, {
                    text: 'Field',
                    value: 'Field'
                }, {
                    text: 'Wholesale',
                    value: 'Wholesale'
                }]
            }
        },
        agents_loading: function() {
            return this.$store.state.entities.agent.loading;
        }
    },
    methods: {
        loadNotice: function(id) {
            let g = this
            QuilityAPI.getPopupNotice(id)
                .then(function(item) {
                    if (id == 'new') {
                        g.needs_link = false
                        g.send_sms = false
                    } else {
                        g.needs_link = item.data.link_text != null;
                        g.send_sms = item.data.send_sms == true
                    }
                    g.popup_notice = item.data;
                    if (g.popup_notice.audience == "agent_codes") {
                        g.pasted_agents = g.popup_notice.selected_agents.join(",")
                    }
                    g.$nextTick(function() {
                        g.$refs.editor.setHtml(item.data.content)
                    })
                    g.loadAvailableAgents(g.popup_notice.audience, false)
                    g.$forceUpdate();
                })
                .catch(function(err) {
                    g.showError("Whoops! " + err)
                    console.log("error", err)
                    //g.$router.push("/404")
                });
        },
        loadAvailableAgents: debounce(function(type, reset_selected_agents) {
            let g = this
            if (g.hasRole('AgencyOwner')) {
                var agent_code = g.user.AgentCode;
                type = "mastershop"
            } else {
                var agent_code = typeof g.selectedAgentCode == 'undefined' ? null : g.selectedAgentCode;
            }
            g.show_search_results = true
            let isAgencyOwner = g.hasRole('AgencyOwner');
            QuilityAPI.getAgentDownlineSearch(agent_code, {
                simplesearch: g.agent_search,
                "Status": "Active",
                limit: 20
            }, type, isAgencyOwner, function(resp) {
                Agent.create({ data: resp.data });
            });
        }, 500),
        addAgent(key) {
            var a = { "AgentCode": this.available_agents[key].AgentCode, "AgentName": this.available_agents[key].AgentName };
            if (this.popup_notice.audience == 'agents') {
                if (this.popup_notice.selected_agents == null) {
                    this.$set(this.popup_notice, 'selected_agents', {});
                }
                this.$set(this.popup_notice.selected_agents, a.AgentCode, a);
                this.available_agents.splice(key, 1);
            } else {
                this.popup_notice.selected_agents = {};
                this.$set(this.popup_notice.selected_agents, a.AgentCode, a);
                //Agent.create({ data: [] })
                this.agent_search = null
            }
        },
        removeAgent(code) {
            let g = this;
            let a = g.popup_notice.selected_agents[code];
            g.available_agents.push(a);
            g.$delete(g.popup_notice.selected_agents, code);
        },
        savePopup() {
            var g = this
            if (g.popup_notice.audience == 'agent_codes') {
                g.popup_notice.selected_agents = g.pasted_agents.split(",")
            }
            g.popup_notice.content = g.$refs.editor.getHtml()
            g.popup_notice.AgentCode = g.user.AgentCode
            g.popup_notice.send_sms = g.send_sms
            g.popup_notice.active = g.popup_notice.active != 1 ? 0 : 1
            g.popup_notice.filters.PCSAgentInd = g.popup_notice.filters.PCSAgentInd != 1 ? 0 : 1
            g.popup_notice.filters.IsSFGDirect = g.popup_notice.filters.IsSFGDirect != 1 ? 0 : 1
            g.popup_notice.filters.State = g.popup_notice.filters.State != null ? g.popup_notice.filters.State : null
            g.popup_notice.add_acknowledge_btn = g.popup_notice.add_acknowledge_btn != 1 ? 0 : 1

            if (!this.validateSmsSettings())
                return

            this.$refs.form.validate()
            if (this.form_valid) {
                if (this.popup_notice.id > 0) {
                    QuilityAPI.updatePopupNotice(this.popup_notice).then(function(resp) {
                        if (resp.error) {
                            g.showError(resp.error)
                            return;
                        }
                        PopupNotice.insert({ data: resp.data })
                        g.showSuccess("Saved!")
                    })
                } else {
                    QuilityAPI.createPopupNotice(this.popup_notice).then(function(resp) {
                        PopupNotice.insert({ data: resp.data })
                        g.popup_notice = resp.data
                        g.showSuccess("Created!")
                    })
                }
            }
        },
        previewPopup() {
            this.popup_notice.content = this.$refs.editor.getHtml();
            this.popup_notice.show = true
            this.previewKey = Math.random()
        },
        previewSMS() {
            var g = this


            if (this.preview_phone_number.length < 10) {
                this.showError('Enter a valid phone number.')
                return
            } else {
                localStorage.setItem("preview_phone_number", this.preview_phone_number)
            }

            if (this.popup_notice.title == null && this.popup_notice.content == null) {
                this.showError('You must enter a message in order to send a preview sms.')
                return
            }

            QuilityAPI.previewPopupNoticeSMS(this.popup_notice, this.preview_phone_number).then(function(resp) {
                if (resp.error) {
                    g.showError(resp.error)
                    return;
                }
                g.showSuccess("Preview Sent")
            })

            this.show_preview_sms_form = false

        },
        validateSmsSettings() {
            if (!this.send_sms)
                return true

            let today = new Date()
            if (this.popup_notice.startDate) {
                let startDate = new Date(this.popup_notice.startDate)
                if (startDate > today) {
                    this.showError('A future start date can not be used when sending an SMS. SMS messages are sent immediately upon saving the notice.')
                    return false;
                }
            }

            if (this.popup_notice.endDate) {
                let endDate = new Date(this.popup_notice.endDate)
                if (endDate < today) {
                    this.showError('A past end date can not be used when sending an SMS. SMS messages are sent immediately upon saving the notice.')
                    return false;
                }
            }
            return true
        },
        characterCount: function() {
            if (this.popup_notice.content == null)
                return 0
            return this.popup_notice.content.replace(/(<([^>]+)>)/gi, "").length
        },
    },
    watch: {
        '$route.params.id': function(newV, oldV) {
            var g = this
            this.popup_notice = { filters: {} };
            if (typeof newV != 'undefined') {
                g.loadNotice(newV);
            }
        },
        'needs_link': function(newV) {
            if (!newV) {
                var pn = this.popup_notice;
                pn.link = null
                pn.link_text = null
                PopupNotice.update({ data: pn });
            }
        },
        'popup_notice.audience': function(newV) {
            //this.$set(this.popup_notice, 'selected_agents', {});
            this.agent_search = null
            this.available_agents = Agent.query().orderBy('AgentName', 'asc').get();
            if (newV != 'agents') {
                this.$set(this.popup_notice, 'selected_agents', {});
            }
        },
        'agent_search': function(newV) {
            if (newV.length > 3) {
                this.loadAvailableAgents(this.popup_notice.audience)
            }
        },
        'send_sms': function(newV, oldV) {
            this.validateSmsSettings()
        },
        'popup_notice.startDate': function() {
            this.validateSmsSettings()
        },
        'popup_notice.endDate': function() {
            this.validateSmsSettings()
        }
    },
    components: {
        QPopupSubmenu,
        TextEditor,
        QTooltipDetails,
        MinimalTextEditor,
        QAdminNotices
    }
};
</script>
<style scoped>
.result-list {
    height: 320px;
    overflow: scroll;
}
</style>
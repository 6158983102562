<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-stored-proc-submenu></q-stored-proc-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer">
            <v-col cols="12">
                <q-snapshot-agents-data-table :proc-params="procParams">
                    <template v-slot:filters>
                        <v-row wrap>
                            <v-col cols="3">
                                <v-text-field v-model="startDate" label="Start Date"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="endDate" label="End Date"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="apvCap" label="APV Cap"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-combobox multiple chips v-model="leadLevels" label="Lead Levels" :items="lead_levels"></v-combobox>
                            </v-col>
                        </v-row>
                    </template>
                </q-snapshot-agents-data-table>
            </v-col>
            <v-col cols="3">
                <h4>Submitted</h4>
                Gross amount of applications for the month.
            </v-col>
            <v-col cols="3">
                <h4>Net Placed</h4>
                Net placed is the amount of business on
                the books that has been issued for that
                month, minus any business that has fallen
                off from previous months (canceled policies/
                chargebacks).
            </v-col>
            <v-col cols="3">
                <h4>Net Placed Percentage (NPP)</h4>
                Net Placed divided by Submit. The minimum acceptable net
                placed percentage is 65%.
            </v-col>
            <v-col cols="3">
                <h4>Minimum Promotable Premium (MPP)</h4>
                MPP is the percentage of an agent’s total
                submitted monthly APV that is net placed.
                The acceptable MPP is 65% of the qualifying
                premium. The production requirements outlined
                in the SFG Promotion Guidelines are based on
                MPP. These requirements must be met during
                each qualifying month.
            </v-col>
            <v-col cols="3">
                <h4>Paid Premium Per Lead (PPL)</h4>
                PPL is the net placed premium divided by the
                number of A leads taken. For example, $10,000
                net placed per month divided by 40 A leads
                results in a PPL of $250.
            </v-col>
            <v-col cols="3">
                <h4>Profitability Index (PI)</h4>
                PI is the Paid Premium Per Lead (PPL) multiplied
                by the net placed %.
            </v-col>
            <v-col cols="3">
                <h4>Close Ratio</h4>
                Apps divided by Leads.
            </v-col>
            <v-col cols="3">
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QSnapshotAgentsDataTable from '@/components/datatables/StoredProc/QSnapshotAgentsDataTable.vue'
import QStoredProcSubmenu from '@/components/navigation/Submenus/QStoredProcSubmenu.vue'
export default {
    name: "SnapshotAgents",
    data() {
        return {
            startDate: '2020-05-01',
            endDate: '2020-07-01',
            apvCap: 7500,
            leadLevels: ['A'],

        }
    },
    mounted: function() {

    },
    computed: {
        procParams: function() {
            return {
                startDate: this.startDate,
                endDate: this.endDate,
                apvCap: this.apvCap,
                leadLevels: this.leadLevels,
            }
        },
        lead_levels: function() {
            return [
                '1A',
                '25C',
                'A4',
                'A3',
                'A2',
                '50C',
                'A1',
                'A',
                'A1',
                'A2',
                'A3',
                'CI',
                'CI2',
                'DK',
                'IA'
            ];
        }
    },
    methods: {

    },
    components: {
        QSnapshotAgentsDataTable,
        QStoredProcSubmenu
    }
};

</script>

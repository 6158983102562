import { render, staticRenderFns } from "./QConfigureAffiliateWidget.vue?vue&type=template&id=460bcd59&scoped=true&"
import script from "./QConfigureAffiliateWidget.vue?vue&type=script&lang=js&"
export * from "./QConfigureAffiliateWidget.vue?vue&type=script&lang=js&"
import style0 from "./QConfigureAffiliateWidget.vue?vue&type=style&index=0&id=460bcd59&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460bcd59",
  null
  
)

export default component.exports
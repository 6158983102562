<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-admin-audit-submenu></q-admin-audit-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer" id="OnboardingReport">
            <v-col cols="12">
                <q-audit-data-table key="WritingNumberAuditReport" :rows="50" :agent-code="$route.params.agentcode" api-function="getWritingNumberAudit" ref="datatable" title="Writing Number Audit Report"></q-audit-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAuditDataTable from '@/components/datatables/Audit/QAuditDataTable.vue'
import QAdminAuditSubmenu from '@/components/navigation/Submenus/QAdminAuditSubmenu.vue';

export default {
    name: "Agents",
    data() {
        return {};
    },
    mounted: function() {},
    computed: {

    },
    methods: {},
    watch: {

    },
    components: {
        QAdminAuditSubmenu,
        QAuditDataTable
    }
};

</script>

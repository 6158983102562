<template>
    <div>
        <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
    </div>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'

export default {
    data() {
        var g = this
        return {
            title: "Audit & Logs",
            titlelink: null,
        }
    },
    mounted: function() {

    },
    computed: {
        submenu: function() {
            return []
        },
        buttons: function() {
            return [{
                label: "Agent Audit",
                to: "/admin/audit/agent-audit",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Logs",
                to: "/admin/audit/logs",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Activity log",
                to: "/admin/audit/agent_logins",
                icon: "fas fa-arrow-alt-circle-right",
            }]
            return [];
            /*return [{
                    label: "Builders Corner",
                    to: "/page/builders-corner-new",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }]*/
        }
    },
    components: {
        QSubmenu,
    },
    methods: {},
}

</script>
<style>
</style>

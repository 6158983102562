<template>
    <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Stored Procedures",
            titlelink: [],
            buttons: [{
                    label: "Agent Upline Carrier",
                    to: "/admin/storedproc/AgentUplineCarrier",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Agents",
                    to: "/admin/storedproc/SnapshotAgents",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Agency Leader board",
                    to: "/admin/storedproc/SnapshotAgencyLeaderboard",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Snapshot Carrier",
                    to: "/admin/storedproc/SnapshotCarrier",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ],
            submenu: []
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>

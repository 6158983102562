<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-admin-audit-submenu></q-admin-audit-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer" id="OnboardingReport">
            <v-col cols="12">
                <q-agent-log-data-table :rows="50" :agent-code="$route.params.agentcode" api-function="getAgentUserLog" ref="datatable" title="Agent User Log" default-sort-column="CreateDate" :default-filter-keys="['Action','Label']" :default-filters="{'Action' : 'PageView'}"></q-agent-log-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAgentLogDataTable from '@/components/datatables/Audit/QAgentLogDataTable.vue'
import QAdminAuditSubmenu from '@/components/navigation/Submenus/QAdminAuditSubmenu.vue';

export default {
    name: "Agents",
    data() {
        return {};
    },
    mounted: function() {},
    computed: {

    },
    methods: {},
    watch: {

    },
    components: {
        QAdminAuditSubmenu,
        QAgentLogDataTable
    }
};

</script>

<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-stored-proc-submenu></q-stored-proc-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer">
            <v-col cols="12">
                <q-agent-upline-carrier-data-table :proc-params="procParams">
                    <template v-slot:filters>
                        <v-row wrap>
                            <v-col cols="3">
                                <v-text-field v-model="startDate" label="Start Date"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="endDate" label="End Date"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="apvCap" label="APV Cap"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-combobox multiple chips v-model="leadLevels" label="Lead Levels" :items="lead_levels"></v-combobox>
                            </v-col>
                        </v-row>
                    </template>
                </q-agent-upline-carrier-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAgentUplineCarrierDataTable from '@/components/datatables/StoredProc/QAgentUplineCarrierDataTable.vue'
import QStoredProcSubmenu from '@/components/navigation/Submenus/QStoredProcSubmenu.vue'
export default {
    name: "StoredProcedures",
    data() {
        return {
            startDate: '2020-05-01',
            endDate: '2020-07-01',
            apvCap: 7500,
            leadLevels: ['A', 'A1'],

        }
    },
    mounted: function() {

    },
    computed: {
        procParams: function() {
            return {
                startDate: this.startDate,
                endDate: this.endDate,
                apvCap: this.apvCap,
                leadLevels: this.leadLevels,
            }
        },
        lead_levels: function() {
            return ['A', 'A1', 'A2'];
        }
    },
    methods: {

    },
    components: {
        QAgentUplineCarrierDataTable,
        QStoredProcSubmenu
    }
};

</script>

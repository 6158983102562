<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">Acknowledgement Log
                            <v-btn icon @click="loadData" dark>
                                <v-icon small>fas fa-sync-alt</v-icon>
                            </v-btn>
                            <v-switch
                                dark
                                v-model="unacknowledged_only"
                                label="Show Unacknowledged Only"
                            ></v-switch>
                        </p>
                        <!--
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Agents
                            </v-btn>
                        </p>
                        //-->
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-data-table :mobile-breakpoint="2" :search.sync="search" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0" :footer-props="{itemsPerPageOptions:[25,50,100]}" :loading="loading" :options.sync="options">
                <template v-slot:item.Email="{ item }">
                    <a :href="'mailto:' + item.Email" class="nowrap">{{ item.Email }}</a>
                </template>
                <template v-slot:item.acknowledged_date="{ item }">
                    <span class="nowrap" v-if="item.acknowledgement_date">
                        {{ formatDate(item.acknowledgement_date) }}
                    </span>
                </template>
                <template v-slot:item.acknowledged="{ item }">
                    <span class="nowrap">
                        <v-icon small v-if="item.acknowledged">fas fa-thumbs-up</v-icon>
                    </span>
                </template>
                <!--
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn small text class="q_new_b_1--text white--text  body-2" :to="'/admin/popup-notices/' + item.id">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>
                    </span>
                </template>
                //-->
                <template v-slot:item.created_at="{ item }">
                    <span class="nowrap">
                        {{ formatDate(item.created_at) }}
                    </span>
                </template>
                <!--
                <template v-slot:header.data-table-select="{item}">
                    <div>
                        <v-tooltip :open-on-hover="true" top>
                            <template v-slot:activator="{ on }">
                                <v-checkbox v-model="selectAll" v-on="on"></v-checkbox>
                            </template>
                            <span>Add All to Power Dailer</span>
                        </v-tooltip>
                    </div>
                </template>
                //-->
                <template v-slot:body.append>
                    <tr>
                        <td colspan=10>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="dialog = true">Send to Power Dialer</v-btn>
                        </td>
                        <td colspan=2>
                            <v-btn dark color="q_leads_1" v-on:click="showSyncDialog">Sync Disposition</v-btn>
                        </td>
                    </tr>-->
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import PopupLog from '@/store/Models/PopupLog'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QPopupLogDataTable",
    //mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'popup_log',
            sync_dialog: false,
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            options: { sortBy: ['acknowledgement_date'], sortDesc: [true] },
            search: null,
            unacknowledged_only: false
        }
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        all_data: function() {
            return PopupLog.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get()
        },
        the_headers: function() {
            return [{
                    text: '',
                    value: 'acknowledged',
                    sortable: false,
                    filterable: true,
                    align: 'end',
                    width: '50px'
                }, {
                    text: 'Agent Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    class: 'header-class',
                },

                {
                    text: 'Date Acknowledged',
                    value: 'acknowledged_date',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Created',
                    value: 'created_at',
                    sortable: true,
                    filterable: true
                },
                /* {
                                    text: '',
                                    value: '$id',
                                    sortable: false,
                                    filterable: false
                                }*/
            ]
        },
        loading: function() {
            return this.$store.state.entities.popup_log.loading
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            QuilityAPI.getPopupLog(this.$route.params.id, { itemsPerPage: 'all', notAcknowledged: this.unacknowledged_only ? '1' : '0' }, opt);
            this.firstPull = false;
        }, 200),
        showSyncDialog: function() {
            this.sync_dialog = true;
            var g = this;
            setTimeout(function() { g.sync_dialog = false }, 4500);
        },
        resetData() {
            this.search = null;
            this.options.page = 1;
            this.refreshData()
        }
    },
    watch: {
        '$route.params.id': function(newV, oldV) {
            var g = this
            if (typeof newV != 'undefined') {
                this.$nextTick(g.loadData());
            }
        },
        'unacknowledged_only': function(newV, oldV) {
            this.loadData()
        }
    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>

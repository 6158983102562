<template>
    <q-submenu :base-url="baseUrl" :title="title" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    name: 'QPopupSubmenu',
    data() {
        return {
            title: "Popup Notices",
            baseUrl: '/admin/popup-notices/',
            buttons: [{
                label: "Popup List",
                anchor: "#popuplist",
                icon: "fas fa-arrow-alt-circle-down",
            }, ],
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>

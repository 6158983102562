<template>
    <v-container fluid>
        <QTabs dark v-model="tab">
            <v-tab>Instructions</v-tab>
            <v-tab>Configure Widget</v-tab>
        </QTabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="pa-12">
                <v-row>
                    <v-col cols="12">
                        <page slug="affiliate-widget"></page>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="pa-6">
                <v-row>
                    <v-col cols="12" class="deep-purple  white--text">
                        <span>Configure Affiliate RAQ Form Widget</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-alert type="warning" v-if="noSBLIWritingNumber">
                            This agent is not licensed with SBLI.
                        </v-alert>
                        <q-agent-lookup :filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated'}" label="Affiliate Agent" v-model="embedAgent" :rules="[rules.required]" placeholder="Start typing to Search for an agent"></q-agent-lookup>
                    </v-col>
                </v-row>
                <v-row v-if="embedAgent">
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="talkToAgent" label="Enable Talk to Agent Option"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="newPageForApplication" label="Open Application In New Window"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="anonymousLeads" label="Allow Anonymous Quotes"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="embedAgent">
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="isDemo" label="Use development version of the widget (for testing purposes only!)"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                            <v-checkbox v-model="useExternalAgentCode" label="Use the agent's external ID as the affiliate code"></v-checkbox>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="#999999" dark v-bind="attrs" v-on="on">
                                        fas fa-question-circle
                                    </v-icon>
                                </template>
                                <span>This would need to be used for agent's whose SBLI affiliate code is different from their agentcode in HQ. It can be edited on the edit agent section of the agent details page.</span>
                            </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="useSBLIDemo" label="Redirect to SBLI demo site (for testing/demo purposes only)"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="embedAgent">
                    <v-col cols="12">
                        <v-alert type="warning" v-if="invalidURL">
                            This does not appear to be a valid URL. This may cause problems when redirecting to this link.
                        </v-alert>
                        <v-text-field label="Thank you Page URL (The sbli app will redirect to this link)" v-model="thankYouPageURL"></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="embedAgent">
                    <v-col cols="12">
                        <v-card flat>
                            <v-row align="center" justify="center">
                                <v-col cols="12" md="3">
                                    <span style="font-weight:bold;">Header Color</span>
                                    <v-color-picker dot-size="15" swatches-max-height="200" v-model="pickerColor" mode="hexa"></v-color-picker>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card flat>
                            <v-row align="center" justify="center">
                                <v-col  cols="12" md="6">
                                    <pre>{{ computed_embed_code }}</pre>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="embedAgent">
                    <v-col cols="12">
                        <v-btn @click="copyCode">Copy Code</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn @click="resetFields">Reset</v-btn>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>

    </v-container>
</template>
<script>
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import rules from '@/components/utils/ValidationRules'
import QuilityAPI from "@/store/API/QuilityAPI.js"
import QTabs from '../navigation/QTabs.vue'
import Page from '@/CMS/views/index.vue'
export default {
    name: "QConfigureAffiliateWidget",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            embedAgent: null,
            talkToAgent: false,
            thankYouPageURL: null,
            newPageForApplication: false,
            pickerColor: null,
            anonymousLeads: false,
            rules: rules,
            writingNumbers: null,
            noSBLIWritingNumber: false,
            useSBLIDemo: false,
            isDemo: false,
            useExternalAgentCode: false,
            tab: 0,
        }
    },
    mounted: function() {

    },
    computed: {
        'the_color': function() {
            // defaults to black
            if(this.pickerColor == null) {
                return 'black'
            }

            if(this.pickerColor.hex && this.isValidHex(this.pickerColor.hex)) {
                return this.pickerColor.hex
            }

            return 'black'
        },
        computed_embed_code: function() {
            return this.generateCode()
        },
        invalidURL: function()
        {
            return !this.validURL(this.ThankYouUrl)
        }
    },
    methods: {

        /**
        * Validates hex value
        * @param  {String} color hex color value
        * @return {Boolean} true|false
        */
        isValidHex(color) {
            if(!color || typeof color !== 'string') return false;

            // Validate hex values
            if(color.substring(0, 1) === '#') color = color.substring(1);

            switch(color.length) {
            case 3: return /^[0-9A-F]{3}$/i.test(color);
            case 6: return /^[0-9A-F]{6}$/i.test(color);
            case 8: return /^[0-9A-F]{8}$/i.test(color);
            default: return false;
            }
        },
        async copyCode() {
            const copyCode = this.generateCode()
            try {
                await navigator.clipboard.writeText(copyCode)
                this.showInfo("The widget code has been copied to clipboard for you to paste as needed.")
            } catch (error) {
                this.showInfo("Something went wrong. Please try again later.")
                console.error('clipboard error', error)
            }
        },
        resetFields() {
            this.embedAgent = null
            this.talkToAgent = false
            this.thankYouPageURL = null
            this.newPageForApplication = false
            this.pickerColor = null
            this.anonymousLeads = false
            this.isDemo = false
            this.useSBLIDemo = false
            this.useExternalAgentCode = false
        },
        generateCode() {
            /**
             * generate html for affiliate widget
             */

            const embeddedFields = {
                'AgentCode': this.embedAgent,
                'TalkToAgent': this.talkToAgent,
                'ThankYouUrl': this.thankYouPageURL,
                'OpenAppInNewPage': this.newPageForApplication,
                'FormColor': this.the_color, // use computed to validate hex value to ensure that we're passing a valid value
                'AnonymousLeads': this.anonymousLeads,
                'UseSBLIDemo': this.useSBLIDemo,
                'useExternalAgentCode': this.useExternalAgentCode,
            }

            const embeddedRaqCode = 'MyRaqComponent('+ JSON.stringify(embeddedFields) +').render(\'#quility-raq\');'
            const embeddedAnonymousRaqCode = 'MyAnonymousRaqComponent('+ JSON.stringify(embeddedFields) +').render(\'#quility-raq\');'

            let divNode = document.createElement("div")
            let zoidNode = document.createElement("script")
            let raqNode = document.createElement("script")
            let embeddedScriptNode = document.createElement("script")
            let embeddedScriptCodeNode = document.createTextNode(this.anonymousLeads ? embeddedAnonymousRaqCode : embeddedRaqCode)

            divNode.setAttribute("id", "quility-raq")

            zoidNode.src = this.isDemo ? "https://dev-affiliate-dtc.quility.com/zoid.js" : "https://affiliate-dtc.quility.com/zoid.js"
            raqNode.src = this.isDemo ? "https://dev-affiliate-dtc.quility.com/MyRaqComponentDev.js" : "https://affiliate-dtc.quility.com/MyRaqComponent.js"

            embeddedScriptNode.type = "text/javascript"
            embeddedScriptNode.appendChild(embeddedScriptCodeNode)

            let copyCode = divNode.outerHTML + zoidNode.outerHTML + raqNode.outerHTML + embeddedScriptNode.outerHTML

            return copyCode
        },
        validURL: function(url) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(url);
        }
    },
    watch: {
        embedAgent: async function(agentCode) {
            try {
                let cwnData = await QuilityAPI.getCarrierWritingNumbers(agentCode)
                let sbliElm = cwnData.data.find( data => data.CarrierName == "SBLI")
                this.noSBLIWritingNumber = sbliElm ? false : true

                if(agentCode)
                {
                    let agent = await QuilityAPI.getAgentStateless(agentCode)
                    this.useExternalAgentCode = agent.ExtUID ? true : false
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    components: {
        QAgentLookup,
        QTabs,
        Page
    }
}
</script>
<style scoped>
    pre {
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }
</style>
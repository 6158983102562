<template>
    <div class="text-left">
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" id="audit_datatable">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 ml-5 white--text">{{ title }}
                            <v-btn dark icon @click="refreshData">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <!-- <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filters
                            </v-btn>
                            
                            <v-btn text small color="q_leads_4" @click="exportResults">
                                <v-icon small left>fas fa-file-export</v-icon> Export Results
                            </v-btn>
                            //
                        </p> -->
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table dense :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" item-key="ID" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="data_loading" :server-items-length="total_items" :options.sync="options">
                <template v-slot:item="{ headers, index, item }">
                    <tr>
                        <td v-for="(h) in headers" :key="index + h.value">
                            <a v-if="h.value == 'Email'" :href="'mailto:' + item.Email" class="nowrap">{{ item.Email }}</a>
                            <v-btn v-else-if="h.value == 'AgentCode'" x-small color="q_new_b_1 white--text  body-2" :to="{ name: 'AgentDetail', params: { agentcode: item.AgentCode} }">View</v-btn>
                            <span v-else-if="h.value == 'ActivityLogUserID'" class="nowrap">
                                <v-btn x-small color="q_new_b_1 white--text  body-2" :to="{ name: 'UserLogReport', params: { agentcode: item.AgentCode} }">View Activty</v-btn>
                            </span>
                            <span v-else-if="always_show.indexOf(h.value) > -1 || item.Action == 'INSERT'">
                                {{item[h.value]}}
                            </span>
                            <span class="nowrap" v-else-if="(item[h.value] != item['prev_' + h.value])">
                                <strike>{{item["prev_" + h.value]}}</strike><br>{{item[h.value]}}
                            </span>
                            <span v-else></span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv');

export default {
    name: "QWeeklyOnboardingReportDataTable",
    props: ['apiFunction', 'agentCode', 'defaultSortColumn', 'defaultFilterKeys', 'defaultFilters'],
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: this.defaultFilters ? this.defaultFilters : {},
            the_data: [],
            data_loading: false,
            total_num: 0,
            options: {
                'sortBy': this.defaultSortColumn ? [this.defaultSortColumn] : ['LastChangeDate'],
                'sortDesc': [true],
                itemsPerPage: 50
            },
            entity: 'audit',
            agent_code: null,
            the_available_filters: null,
            columns: [],
            always_show: ["ID", "Action", "AgentCode", "AgentName", "LastChangeBy", "LastChangeDate", "OrderNumber"]
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            if (this.defaultFilterKeys) {
                return this.defaultFilterKeys;
            }
            return ['LastChangeBy', 'Action', 'Division'];
        },
        total_items: function() {
            return this.total_num;
        },
        available_filters: function() {
            return this.the_available_filters;
        },
        the_headers: function() {
            return this.columns
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI[this.apiFunction](this.filters, { ...opt }, this.agentCode).then(function(json) {
                g.data_loading = false
                g.columns = json.columns;
                g.$set(g, 'total_num', json.meta.total);
                if (json.meta.filters) {
                    g.$set(g, 'the_available_filters', json.meta.filters);
                }
                g.$nextTick(function() {
                    g.$set(g, 'the_data', json.data);
                })
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            this.firstPull = false;
        }, 200),
        exportResults: function() {
            /*
            var fields = ['AgentName', 'AgentCode', 'NPN', 'AgentEmail', 'ContractStartDate', 'UplineAgentName', ];
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(this.all_data);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify("Weekly Onboarding Report");
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (err) {
                console.error(err);
            }
            */
        },
    },
    watch: {
        'agentCode': function(newV, oldV) {
            this.$nextTick(this.refreshData);
        }
    },
    components: {}
}

</script>
<style>
.smaller-text.v-data-table td {
    font-size: .65em;
}

.smaller-text.v-data-table td {
    padding: 0px 8px 0px;
}

.smaller-text.v-data-table--dense td {}

</style>
